<template>
  <m-infinite-scroll
    :load="load"
    :pagination="pagination"
    icon="map"
    resource="journeys"
  >
    <div class="row q-col-gutter-md">
      <div
        v-for="item in items"
        :key="item.reference"
        class="col-md-4"
      >
        <router-link :to="`/journeys/${item.reference}/timeline`">
          <journey-card
            :label="`#${item.reference}`"
            :date="item.date.date"
            v-bind="item"
          />
        </router-link>
      </div>
    </div>
  </m-infinite-scroll>
</template>
<script>
import { mapGetters } from 'vuex'
import { journeys } from 'api/user'
import journeyCard from './journey-card'
import { MInfiniteScroll } from 'components/'

export default {
  name: 'MJourneyCardList',
  components: {
    journeyCard,
    MInfiniteScroll
  },
  props: [ 'journeysUser' ],
  data () {
    return {
      items: [],
      pagination: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    load (index) {
      return journeys({ username: this.journeysUser }, { page: index, limit: 15 })
        .then(res => {
          this.pagination = res.meta.pagination
          this.items = this.items.concat(res.data)
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.layout-padding
  max-width 1024px
.col-md-4
  padding 8px 0 0 8px
  max-width 100%
  @media (min-width 768px)
    width 33%
</style>
